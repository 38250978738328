import React, { useContext } from "react";
import { Link, withRouter } from "react-router-dom";

import { AuthContext } from "../AuthStore";

import SearchBar from "./SearchBar";
import Profile from "./Profile";
import FixedMenu from "./FixedMenu";
import ConfigurableMenuContainer from "./ConfigurableMenuContainer";

const NavBar = (): JSX.Element => {
    const authContext = useContext(AuthContext);
    const { loggedIn, user } = authContext;
    return (<nav className="navbar navbar-expand-lg navbar-dark bg-primary">
        <Link to="/" className="navbar-brand mr-4">
            <h1 className="title-header">MODS</h1>
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
        </button>
        { loggedIn
            ? (<div className="flex-column collapse navbar-collapse" id="navbar">
                <ul className="navbar-nav nav w-100">
                    <div className="col-md-9">
                        { user && !user.isGuest &&
                            <SearchBar />
                        }
                    </div>
                    <div className="col-md-3 float-right wrap-text">
                        <Profile />
                    </div>
                    <FixedMenu />
                    <ConfigurableMenuContainer />
                </ul>
            </div>)
            : ("")
        }
    </nav>);
};

export default withRouter(NavBar);
