/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import TitleContainer from "../Title/Container";
import RenderContent from "../InlineIconText";
import { GHRProvider as GHRProviderType } from "../../models/GHRProvider";

interface Props {
    data: {
        content: GHRProviderType;
    };
}

const GHRProvider = (props: Props): JSX.Element => {
    const { data: { content: ghr } } = props;
    const isDeactivated = ghr?.isDeactivated || false;
    return (<div className={`search-result row d-flex align-items-stretch border border-1 ${isDeactivated ? "search-result-deactivated" : ""}`}>
        <div className="text col-lg-12">
            <div className="text-inner d-flex align-items-center">
                <div>
                    <header className="header">
                        <div className="search-result-title">
                            <h5>
                                <Link to={`/ghr-providers/${ghr.id}`} className="active">
                                    {ghr.name}
                                </Link>
                            </h5>
                        </div>
                    </header>
                    <p></p>
                    <footer className="search-result-footer d-flex justify-content-start flex-wrap">
                        <div className="title font-weight-bold">
                            {RenderContent("fa fa-lock", ghr.credential.toString())}
                        </div>
                        <div className="title">
                            { ghr.members && ghr.members.filter(member => member.title === "System Admin").map(sysMember => (<span key={sysMember.member}>
                                <i className="fa fa-address-book" key={sysMember.member} />
                                <span className="text ml-2 mr-2">
                                    <TitleContainer key={sysMember.member} fetchProps={{
                                        id: sysMember.member
                                    }} />
                                </span>
                            </span>))
                            }
                        </div>
                        <div className={`title ${!isDeactivated ? "title-last" : ""}`}>
                            {RenderContent("fa fa-calendar-o", moment(new Date(ghr.memberSince)).format("MMMM Do, YYYY"))}
                        </div>
                        {isDeactivated && <div className="title title-last">
                            {RenderContent("fa fa-ban", "Inactive")}
                        </div>}
                    </footer>
                </div>
            </div>
        </div>
    </div>);
};

export default GHRProvider;
