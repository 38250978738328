/* eslint-disable @typescript-eslint/no-floating-promises */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
        .then(registration => {
            registration.unregister();
        }).catch(error => {
            console.error(error.message);
        });
    }
}
