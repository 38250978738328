import React, { Component, ComponentType } from "react";
import _ from "lodash";
import { DefaultProps } from "./WithDefaulting";
import { ErrorProps } from "./WithErrorHandling";
import { LoadingProps } from "./WithLoading";
import { FetchProps, WrappedComponentDataFetchingProps } from "./interfaces";

export interface DataFetchingProps {
    fetchProps?: FetchProps;
}

export interface DataFetchingState<T> extends DefaultProps, ErrorProps, LoadingProps {
    data: T;
    fetchProps?: FetchProps;
    shouldFetch: boolean;
    saveHandler?: (object: T, previousObject?: T) => Promise<T>;
}

export interface DataFetchingParams <T> {
    fetchHandler?: (fetchProps?: FetchProps) => Promise<T>;
    saveHandler?: (object: T, previousObject?: T) => Promise<T>;
}


// withDataFetching:
// - takes an argument of type DataFetchingParams<T>
// - wraps a component with prop types P & WrappedComponentDataFetchingProps<T> & DefaultProps & ErrorProps & LoadingProps
// - returns a component with prop types P & DataFetchingProps

const withDataFetching = <P extends object, T extends unknown>({ fetchHandler, saveHandler }: DataFetchingParams<T>) => (WrappedComponent: ComponentType<P & WrappedComponentDataFetchingProps<T> & DefaultProps & ErrorProps & LoadingProps>): ComponentType<P & DataFetchingProps> => {
    class WithDataFetching extends Component<P & DataFetchingProps, DataFetchingState<T>> {
        state: DataFetchingState<T> = {
            data: {} as T,
            fetchProps: this.props.fetchProps && this.props.fetchProps,
            shouldFetch: Boolean(this.props.fetchProps),
            loading: Boolean(this.props.fetchProps),
            hasError: false,
            useDefault: false,
            saveHandler
        };

        componentDidUpdate(prevProps: DataFetchingProps) {
            if (prevProps && prevProps.fetchProps && this.props && this.props.fetchProps) {
                if (!_.isEqual(prevProps.fetchProps, this.props.fetchProps)) {
                    this.props.fetchProps && this.refetch(this.props.fetchProps);
                }
            }
        }

        componentDidMount() {
            this.fetch();
        }

        refetch = (fetchProps: FetchProps) => {
            this.setState({
                fetchProps
            }, () => this.fetch());
        };

        //data could be an array or an object, check to see if they are empty at which point we show the default component.
        fetch() {
            const {
                fetchProps,
                shouldFetch
            } = this.state;
            shouldFetch && fetchHandler && fetchHandler(fetchProps)
                .then((data) => {
                    this.setState({
                        data,
                        loading: false,
                        hasError: false,
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        useDefault: Boolean(fetchProps) && (!data || (data as any).length === 0)
                    });
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        hasError: true,
                        error
                    });
                });
        }

        render() {
            return (<React.Fragment>
                <WrappedComponent {...this.state} {...this.props} refetchHandler={this.refetch} />
            </React.Fragment>);
        }
    }

    return WithDataFetching;
};

export default withDataFetching;
