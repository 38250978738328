import { ComponentType } from "react";
import { compose } from "redux";
import withDataFetching, { DataFetchingParams, DataFetchingProps } from "./WithDataFetching";
import withErrorHandling, { ErrorProps } from "./WithErrorHandling";
import withDefaulting, { DefaultProps } from "./WithDefaulting";
import withLoading, { LoadingProps } from "./WithLoading";
import { WrappedComponentDataFetchingProps } from "./interfaces";

interface ContainerParams <P, T> {
    dataParams: DataFetchingParams<T>;
    defaultLoadingMessage?: string | "Loading ";
    Error: ComponentType<ErrorProps>;
    DefaultComponent: ComponentType<P>;
}

const withContainer = <P extends object, T> ({ dataParams,
    defaultLoadingMessage,
    Error,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    DefaultComponent } : ContainerParams<P & WrappedComponentDataFetchingProps<T>, T>) => (WrappedComponent: ComponentType<P & WrappedComponentDataFetchingProps<T>>) => {

    type Q = P & WrappedComponentDataFetchingProps<T>;

    // let a: ComponentType<Q & DefaultProps> = withDefaulting(DefaultComponent)(WrappedComponent);
    // let b: ComponentType<Q & DefaultProps & ErrorProps> = withErrorHandling<Q & DefaultProps>(Error)(a);
    // let c: ComponentType<Q & DefaultProps & ErrorProps & LoadingProps> = withLoading<Q & DefaultProps & ErrorProps>(defaultLoadingMessage)(b);
    // let d: ComponentType<P & DataFetchingProps> = withDataFetching<P, T>(dataParams)(c);
    const WithContainer : ComponentType<P & DataFetchingProps> =
        compose<ComponentType<Q & DefaultProps>, ComponentType<Q & DefaultProps & ErrorProps>, ComponentType<Q & DefaultProps & ErrorProps & LoadingProps>, ComponentType<Q>, ComponentType<P & DataFetchingProps>>(
            withDataFetching(dataParams),
            withLoading(defaultLoadingMessage),
            withErrorHandling(Error),
            withDefaulting(DefaultComponent))(WrappedComponent);
    return WithContainer;
};

export default withContainer;
