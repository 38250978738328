import React from "react";
import _ from "lodash";
import { FetchProps, WrappedComponentDataFetchingProps } from "../hoc/interfaces";

export interface PaginationProps {
    pageSize: number;
    totalCount: number;
    pageNum: number;
}

const Pagination = <T extends object>(props: PaginationProps & FetchProps & WrappedComponentDataFetchingProps<T>): JSX.Element => {
    const totalCount = props.totalCount;
    const pageSize = props.pageSize;
    const pageNum = props.pageNum;
    const refetchHandler = props.refetchHandler;
    const fetchProps = props.fetchProps;

    const numberOfPages = Math.ceil(totalCount / pageSize);
    if (numberOfPages === 1) return <React.Fragment />;
    const pages = _.range(1, numberOfPages + 1);

    return (<nav>
        <ul className="pagination">
            {
                pages.map(page => (<li key={page} className={page === pageNum
                    ? "page-item active"
                    : "page-item"}>
                    <span className="page-link" onClick={e => onPageClick(e, page, refetchHandler, fetchProps)}>
                        {page}
                    </span>
                </li>))
            }
        </ul>
    </nav>);
};
export default Pagination;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onPageClick = (e: React.MouseEvent, page: number, refetchHandler: (fetchProps: any) => void, fetchProps: any) => {
    e.preventDefault();
    const updatedProps = fetchProps;
    updatedProps.pageNum = page - 1;
    refetchHandler(updatedProps);
};
