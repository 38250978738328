import { compose } from "redux";

import NewProviderPost from "./NewOrEdit";
import Default from "./Default";
import Error from "../Error";
import withContainer from "../common/hoc/WithContainer";
import extractMandatoryFields from "./ExtractMandatoryFields";

import { createProviderPost } from "../../services/MODSService";

export default compose(
    extractMandatoryFields(),
    withContainer({
        dataParams: {
            saveHandler: createProviderPost
        },
        Error,
        DefaultComponent: Default
    })
)(NewProviderPost);
