/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";
import RenderContent from "../InlineIconText";
import { Organization as OrganizationType } from "../../models/Organization";

interface Props {
    data: {
        content: OrganizationType;
    };
}

const Organization = (props: Props): JSX.Element => {
    const { data: { content: organization } } = props;
    return (<div className="search-result row d-flex align-items-stretch border border-1">
        <div className="text col-lg-12">
            <div className="text-inner d-flex align-items-center">
                <div>
                    <header className="header">
                        <div className="search-result-title">
                            <h5>
                                <Link to={`/organizations/${organization.id}`} className="active">
                                    {organization.name}
                                </Link>
                            </h5>
                        </div>
                    </header>
                    <p></p>
                    <footer className="search-result-footer d-flex justify-content-start flex-wrap">
                        {
                            organization.websiteUrl && organization.websiteUrl.length > 0
                                ? (<div className="title title-last">
                                    {
                                        RenderContent("fa fa-external-link", <a href={organization.websiteUrl} target="_blank" rel="noopener noreferrer">
                                            {organization.websiteUrl}
                                        </a>)
                                    }
                                </div>)
                                : (<div className="title title-last">
                                    <span className="text-gray" />
                                </div>)
                        }
                    </footer>
                </div>
            </div>
        </div>
    </div>);
};

export default Organization;
