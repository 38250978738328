import { compose } from "redux";

import EditProviderPost from "./NewOrEdit";
import Default from "./Default";
import Error from "../Error";
import withContainer from "../common/hoc/WithContainer";
import extractId from "../ExtractId";
import extractMandatoryFields from "./ExtractMandatoryFields";

import { getObjectById, update } from "../../services/MODSService";

export default compose(
    extractId(),
    withContainer({
        dataParams: {
            fetchHandler: getObjectById,
            saveHandler: update
        },
        defaultLoadingMessage: "Retrieving Provider Post Details",
        Error,
        DefaultComponent: Default
    }),
    extractMandatoryFields()
)(EditProviderPost);
